<template>
  <v-container style="height: 100%">
    <v-row class="pa-1">
      <v-col cols="12" v-for="(aCase, index) in cases" :key="index">
        <v-card
            class="mb-1 scale-up-center"
            style="border-radius: 11px;"
            rounded="lg"
            elevation="9"
            @click="$router.push(`/atendimento/${aCase.Id}`)"
        >
          <p
              class="atendimento-title pt-9 pl-7"
              style="font-size: 18px"
          >
            {{ aCase.subject ? aCase.subject : '' }}
          </p>
          <v-card-text>
            <v-row class="pl-3">
              <v-row></v-row>
              <v-col cols="12" class="pb-0 pt-0 font-weight-bold">Benefício / Operadora
              </v-col>
              <p class="font-weight-light pl-3 pr-12 mr-12">
                {{getdescribe(aCase.contract__r.benefits__c, "Benefits__c") ? getdescribe(aCase.contract__r.benefits__c, "Benefits__c") : ''}} /
                {{ aCase.contract__r ? (aCase.contract__r.carrier_2__r ? aCase.contract__r.carrier_2__r.comercial_name__c : '') : '' }}
              </p>
              <v-col cols="5" class="pb-0 pt-0 font-weight-bold">Protocolo
              <p class="font-weight-light">{{ aCase.caseNumber ? aCase.caseNumber : '' }}</p>
              </v-col>
              <v-col cols="7" class="pb-0 pt-0 font-weight-bold">Abertura
              <p class="font-weight-light">{{ aCase.createdDate ? formatDate(aCase.createdDate) : ''}}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContractService from "@/common/ContractService";
import {mapGetters} from "vuex";

export default {
  props: {
    cases:{
      type: Array,
      required: true
    }
  },
  async beforeMount() {
    this._contractservice = new ContractService();
    await this.loadDescribe();
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods : {
    async loadDescribe() {
      await this._contractservice.FindbyDescribe();
      this.describe = JSON.parse(sessionStorage.getItem("describe-contract"));
    },
    formatDate ( dateString ) {
      if (!dateString) return null;
      return this.$util.vueFormatDateToBR(dateString)
    },
    getdescribe(name, campo) {
      return this._contractservice.dsDescribe(this.describe, name, campo);
    }
  }
};
</script>
<style scoped>
.atendimento-type{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  /* identical to box height */
  /* grey / 3 */
  color: #868698;
}
.atendimento-title{
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1px;
  /* identical to box height */
  color: #292867;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.3s ease-in both;
  animation: scale-up-center 0.3s ease-in both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

</style>
