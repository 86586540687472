<template>
  <v-container style="height: 100%" class="px-3 pt-16 bg-effect">
    <Back :route="'menu-nick'"/>
    <Totalizer class="mb-2" :totals="totals" :title="title" color="#292867"/>
    <v-row><v-col>
    <v-layout justify-center align-center>
      <v-card style="background: rgba(231, 234, 254, .6);" class="option-list-card list-card mb-6">
        <v-row justify="center">
          <v-col class="pl-6 pr-0 pt-5" cols="7">
            <div
                :class="!activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
                :style="!activeFilter && 'background: white; height: 50px'"
            >
              <p
                  style="font-size: 16px !important; font-weight: bolder !important;"
                  class="text-center generic-text mb-0"
                  @click="() => activeFilter = !activeFilter"
              >
                Abertos
              </p>
            </div>
          </v-col>
          <v-col class="pl-0 pr-7 pt-5 mt-0" cols="5">
            <div
                :class="activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
                :style="activeFilter && 'background: white; height: 50px'"
            >
              <p
                  style="font-size: 16px !important; font-weight: bolder !important; height: 50%!important;"
                  class="text-center generic-text mb-0"
                  @click="() => activeFilter = !activeFilter"
              >
                Histórico
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-layout>
    <v-layout align-center justify-center>
      <v-row v-if="!activeFilter" align="center" justify="center">
        <v-col cols="12">
          <CasesList
              style="background: rgba(255, 255, 255,0);"
              :cases="openedCases"
          />
        </v-col>
      </v-row>
      <v-row v-if="activeFilter" align="center" justify="center">
        <v-col cols="12">
          <CasesList
              style="background: rgba(255, 255, 255,0);"
              :cases="closedCases"
          />
        </v-col>
      </v-row>
    </v-layout>
      <v-btn
          fab
          color="#EA4965"
          @click="addCase"
          right
          fixed
          bottom
      >
        <img src="/img/icons/common/plus-icon.svg">
      </v-btn>
    </v-col></v-row>
  </v-container>
</template>

<script>
import Totalizer from '@/components/generic-totalizer/index'
import Back from '@/components/covid/back-arrow/index'
import CaseService from "@/services/salesforce/CaseService";
import CasesList from "@/view/atendimento/casesList";
import {mapMutations} from "vuex";


export default {
  name: "miniMenuCovid",
  components: {
    CasesList,
    Back,
    Totalizer,
  },
  data: () => ({
    totals: [
      {
        id: "openedCases",
        label: "Atendimentos <br/> abertos",
        value: 0
      },
      {
        id: "pendingCases",
        label: "Pendentes <br/> com o usuário",
        value: 0
      },
      {
        id: "totalCases",
        label: "Histórico de Atendimentos",
        value: 0
      },
    ],
    activeFilter: false,
    title: "Atendimento",
    tabs: {
      opened: { index: 0 },
      history: { index: 1 }
    },
    openedCases: [],
    closedCases: [],
    _caseService: null
  }),
  created () {
      this._caseService = new CaseService();
      this.loading(true);
      this.loadTotals();
      this.loadOpenedCases();
      this.loadClosedCases();
  },
  methods:{
    ...mapMutations(['showmsg', 'loading']),
    loadTotals(){
      this._caseService.countCaseTotals().then(result => {
        this.totals.forEach((total) => {
          if(total.id === "openedCases"){
            total.value = result.openedCases;
          }
          if(total.id === "pendingCases"){
            total.value = result.pendingCases;
          }
          if(total.id === "totalCases"){
            total.value = result.totalCases;
          }
        })
      }).catch((errors) =>{
          this.showmsg({ text: "Não foi possível carregar os totalizadores.", type: "error" });
      }).finally(() =>{
      });
    },
    loadOpenedCases(){
      this._caseService.findAllOpenedCases().then((result) =>{
        this.openedCases = result;
      }).catch((errors) =>{
        this.showmsg({ text: "Não foi possível carregar os atendimentos abertos.", type: "error" });
      }).finally(() =>{
        this.loading(false);
      });
    },
    loadClosedCases(){
      this._caseService.findAllClosedCases().then((result) =>{
        this.closedCases = result;
      }).catch((errors) =>{
        this.showmsg({ text: "Não foi possível carregar os histórico.", type: "error" });
      }).finally(() =>{
      });
    },
    addCase(){
      this.$router.push("/atendimentos/opcoes");
    }
  }
}
</script>

<style scoped>

/*background effect blur*/
.list-card {
  background-blend-mode: multiply !important;
  border-radius: 20px !important;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
}

.option-list-card {
  width: 100% !important;
  height: 65px !important;
}

.bg-effect {
  background: rgba(255, 255, 255, .55);
  mix-blend-mode: normal;
  padding: 10px;
}

</style>


